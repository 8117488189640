import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./components/Layout";
import theme from "./@chakra-ui/gatsby-plugin/theme";

export const wrapPageElement = ({ element }: { element: React.ReactNode }) => {
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Layout>{element}</Layout>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-na1.hs-scripts.com/47480626.js"
      ></script>
    </ChakraProvider>
  );
};
