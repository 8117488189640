import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Stack,
  chakra,
  keyframes,
  shouldForwardProp,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "gatsby";
import Button from "../Button";
import { navigate } from "gatsby";

import Logo from "../../images/infokool-logo.webp";
import { ArrowBackIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { isValidMotionProp, motion } from "framer-motion";
import { programsData } from "../../data/programsData";

type MenuProps = {
  onClose: () => void;
  isOpen: boolean;
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MenuDrawer = (props: MenuProps) => {
  const { onClose, isOpen } = props;
  const [xAxisValue, setXaxisValue] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState({
    name: "",
    subPrograms: [{ name: "", slug: "" }],
  });
  const increaseXValue = () => {
    setXaxisValue(xAxisValue + 100);
  };
  const decreaseXValue = () => {
    setXaxisValue(xAxisValue - 100);
  };
  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
  };
  const Links = [
    { name: "About", link: "about" },
    { name: "Solutions", link: "solutions" },
    { name: "Contact", link: "contact" },
  ];
  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      size={{ base: "full" }}
      placement="left"
    >
      <DrawerOverlay />
      <DrawerContent bgColor="white">
        <Image
          src={Logo}
          w={150}
          ml={5}
          mt={4}
          onClick={() => {
            navigate("/");
            onClose();
          }}
        />
        <DrawerCloseButton color="black" fontSize="md" mr={3} mt={3} />
        <DrawerBody
          px={0}
          display="flex"
          flexDir="column"
          alignItems="start"
          gap={4}
          justifyContent="center"
          overflow="hidden"
        >
          <ChakraBox
            initial={{ x: "0%" }}
            animate={{ x: xAxisValue + "%" }}
            // @ts-ignore no problem in operation, although type error appears.
            transition={{ duration: "0.3", type: "spring" }}
            display="flex"
            flexDir="row"
            alignItems="center"
            justifyContent={"flex-start"}
            w="100%"
            mt={8}
          >
            <ChakraBox
              display="flex"
              flexDir="column"
              minW="100%"
              alignItems="start"
              px={6}
              gap={3}
            >
              <Box
                fontSize="xl"
                fontWeight="semibold"
                color="black"
                cursor="pointer"
                onClick={decreaseXValue}
              >
                Products
                <ChevronRightIcon boxSize={6} color={"primary.600"} />
              </Box>
              <Divider borderColor="rgba(255, 255, 255, 0.20)" w="50%" />
              {Links.filter((data) => data.name !== "Programs").map(
                (link, index) => (
                  <>
                    <Link
                      to={`/${link.link.toLocaleLowerCase()}`}
                      onClick={onClose}
                    >
                      <Box fontSize="xl" fontWeight="semibold" color="black">
                        {link.name}{" "}
                        {link.name === "Programs" && (
                          <ChevronRightIcon boxSize={6} color={"primary.600"} />
                        )}
                      </Box>
                    </Link>
                    {index !== Links.length - 1 && (
                      <Divider
                        borderColor="rgba(255, 255, 255, 0.20)"
                        w="50%"
                      />
                    )}
                  </>
                )
              )}
            </ChakraBox>

            <ChakraBox
              display="flex"
              flexDir="column"
              gap={5}
              minW="100%"
              px={6}
            >
              <Box fontSize="2xl" fontWeight="semibold" color="black">
                Products
              </Box>
              <Box display="flex" flexDir="column" gap={3}>
                <Link to={`/category/telecommunication`} onClick={onClose}>
                  <Box fontSize="lg" color="black">
                    Telecommunication{" "}
                  </Box>
                </Link>
                <Link to={`/category/industrial`} onClick={onClose}>
                  <Box fontSize="lg" color="black">
                    Industrial{" "}
                  </Box>
                </Link>
                <Link to={`/category/it-and-data-center`} onClick={onClose}>
                  <Box fontSize="lg" color="black">
                    I.T & Data Center{" "}
                  </Box>
                </Link>
                <Link to={`/category/solar-cooling`} onClick={onClose}>
                  <Box fontSize="lg" color="black">
                    Solar Cooling{" "}
                  </Box>
                </Link>
              </Box>
            </ChakraBox>
          </ChakraBox>
          <ChakraBox
            // position={"absolute"}
            // bottom={36}
            // right={20}
            animate={xAxisValue < 0 ? show : hide}
          >
            <IconButton
              mx={6}
              _active={{ bgColor: "rgba(255, 255, 255, 0.20)" }}
              _hover={{ bgColor: "rgba(255, 255, 255, 0.20)" }}
              size={"lg"}
              isRound={true}
              variant="solid"
              isDisabled={xAxisValue === 0}
              bgColor="rgba(0, 0, 0, 0.20)"
              aria-label="Done"
              fontSize={"4xl"}
              icon={<ArrowBackIcon color="black" />}
              onClick={() => {
                increaseXValue();
              }}
            />
          </ChakraBox>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MenuDrawer;
